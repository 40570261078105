<template>
  <div>
    <b-row class="m-0">
      <b-col
        class="content-header"
        cols="12"
      >
        <h2 class="content-header-title float-left m-0 pr-1 mr-1">
          {{ $route.meta.pageTitle }} <span v-show="archive" class="text-danger">&nbsp;(archive)</span>
        </h2>
        <div class="content-header-search mr-1">
          <b-form-input
            id="searchCollaborator"
            v-model="searchCollaborator"
            placeholder="Rechercher une entreprise"
            v-on:input="onFilterTextBoxChanged()"
            class="w-full"
          />
        </div>
        <div class="content-header-actions">
          <b-button
            variant="primary"
            @click="newCollaborator('')"
            class="px-1 mr-1"
          > 
            <feather-icon icon="PlusIcon" />
            Nouveau 
          </b-button>
          <vs-dropdown class="cursor-pointer">
            <div>
              <b-button
                variant="outline-primary"
                class="px-1"
              >
                  <feather-icon icon="SettingsIcon" />
                  <!-- Paramètres -->
              </b-button>
            </div>
            <vs-dropdown-menu class="w-full">
                <vs-dropdown-item class="w-full border-bottom-grey-light" @click="archive=!archive">{{ !archive ? 'Voir les collaborators archivés' : 'Voir les collaborators actifs' }}</vs-dropdown-item>
                <vs-dropdown-item class="w-full border-bottom-grey-light" @click="togglePanelColumn">{{this.gridOptions.sideBar == null? 'Gérer les colonnes' : 'Masquer la gestion des colonnes' }}</vs-dropdown-item>
                <vs-dropdown-item class="w-full" @click="exportDataCSV">Exporter en CSV</vs-dropdown-item>
                <vs-dropdown-item class="w-full" @click="exportDataXLS">Exporter en XLS</vs-dropdown-item>
            </vs-dropdown-menu>
          </vs-dropdown>
        </div>
      </b-col>
    </b-row>
    <div class="content-grid">
      <div class="text-center flex-center" v-if="isLoadingCollaboratorsList || isLoadingCollaboratorsListArchived">
        <div class="spinner-border text-primary" role="status">
          <span class="sr-only">Chargement...</span>
        </div>
        <br />
        {{archive ? 'Chargement des archives en cours...' : 'Chargement en cours de la liste des collaborators...'}}
      </div>
      <ag-grid-vue v-else style="width: 100%; height: 100%;"
          class="ag-theme-material"
          :class="archive ? 'ag-archived' : ''"
          :rowData="gridData"
          :gridOptions="gridOptions"
          :key="archive+filter"
          @grid-ready="onGridReady"
          @rowClicked="onRowClicked">
      </ag-grid-vue>
    </div>
    <sidebar-collaborator ref="sidebarCollaborator"/>
  </div>
</template>
<script>
import { AgGridVue } from "ag-grid-vue"
import { agGridLocaleFR } from "@/data/agGridLocaleFR"

import {
  BTable,
  BFormGroup,
  BFormRadio,
  BFormCheckbox,
  BFormRadioGroup,
  BCardBody,
  BButtonToolbar,
  BButtonGroup,
  BButton,
  VBTooltip
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { mapGetters, mapActions } from 'vuex'


import SidebarCollaborator from '@/components/directory/collaborator/SidebarCollaborator'


export default {
  components: {
    BTable,
    BFormGroup,
    BCardBody,
    BFormRadio,
    BFormCheckbox,
    BFormRadioGroup,
    BButtonToolbar,
    BButtonGroup,
    BButton,
    VBTooltip,
    AgGridVue,
    SidebarCollaborator
  },
  directives: {
    Ripple
  },
  data () {
    return {
      archive:false,
      filter: false,
      sidebar:false,
      activeSidebarCollaborator:false,
      gridOptions:{
        localeText: agGridLocaleFR,
        columnDefs: null,
        defaultColDef: {
          flex: 1,
          minWidth: 100,
          editable: false,
        },
        enableRangeSelection: true,
        pagination:true,
        paginationPageSize:20,
      },
      gridApi: null,
      columnApi: null,
      searchCollaborator: ''
    }
  },
  computed: {
    ...mapGetters([
      'collaboratorsList',
      'collaboratorsListArchived',
      'isLoadingCollaboratorsList',
      'isLoadingCollaboratorsListArchived',
      'isOpenSidebarCollaborator',
    ]),
    gridData () {
      if(this.archive){
        return this.$store.getters.collaboratorsListArchived
      }else{
        return this.$store.getters.collaboratorsList
      }
    }
  },
  beforeMount() {
    this.gridOptions.columnDefs = [
      { field: 'civility', headerName: 'Civilité', lockVisible:true,  sortable: true, headerClass: 'ag-header-cell-center', cellStyle: {textAlign: 'center'}, cellRenderer: 
        params => { return params.value == 0 ? 'M.' : params.value == 1 ? 'Mme.' : 'N.C'  }
      },
      { field: 'lastName', headerName: 'Nom', resizable:true, lockVisible:true, sortable: true, minWidth: 160},
      { field: 'firstName', headerName: 'Prénom', resizable:true, lockVisible:true, sortable: true, minWidth: 160},
      { field: 'phoneNumber', headerName: 'Téléphone', resizable:true, lockVisible:true, sortable: true, minWidth: 118 },
      { field: 'secondaryPhoneNumber', headerName: 'Tél secondaire', resizable:true, sortable: true, minWidth: 118 },
      { field: 'email', headerName: 'Email', resizable:true, wrapText:true, lockVisible:true, sortable: true, minWidth: 260 },
      { field: 'address', headerName: 'Adresse', resizable:true, initialHide:true, sortable: true, width: 'fit-content' },
      { field: 'addressComplement', headerName: 'Complément d\'adresse', wrapText:true, resizable:true, initialHide:true, sortable: true },
      { field: 'zipCode', headerName: 'Code postal', resizable:true, initialHide:true, sortable: true },
      { field: 'city', headerName: 'Ville', resizable:true, initialHide:true, sortable: true },
      { field: 'country', headerName: 'Pays', resizable:true, initialHide:true, sortable: true },
      { field: 'matricule', headerName: 'Code', resizable:true, initialHide:true, sortable: true, maxWidth: 100},
      { field: 'socialSecurityNumber', headerName: 'Numéro de Sécurité Sociale', resizable:true, sortable: true },
      { field: 'job', headerName: 'Métier', resizable:true, sortable: true, maxWidth: 140, cellRenderer: params => {
          return params.value ?  params.value.label : 'N.C'
        } 
      }, 
      { field: 'contract', headerName: 'Contrat', resizable:true, sortable: true, maxWidth: 140, cellRenderer: params => {
          return params.value ?  params.value.label : 'N.C'
        } 
      }
    ];
  },
  methods: {
    ...mapActions([ 
      'fetchCollaboratorsList',
      'fetchCollaboratorsListArchived',
      'deleteCollaborator'
    ]),

    // Gestion d'AgGrid
    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
      this.gridApi.setHeaderHeight(36);
    },
    onRowClicked(event){
      console.log("onRowClicked",event)
      if(this.archive){
        this.$refs['sidebarCollaborator'].getCollaboratorArchivedDetails(event.data.id, 1)
      }else{
        this.$refs['sidebarCollaborator'].getCollaboratorDetails(event.data.id)
      }
      this.$store.commit('SET_IS_OPEN_SIDEBAR_COLLABORATOR', true)
    },
    onFilterTextBoxChanged() {
      this.gridApi.setQuickFilter(
        this.searchCollaborator
      );
    },
    togglePanelColumn(){
      if(!this.gridOptions.sideBar || this.gridOptions.sideBar== null ){
        this.gridOptions.sideBar = {
          toolPanels: [
            {
              id: 'columns',
              labelDefault: 'Colonnes',
              labelKey: 'columns',
              iconKey: 'columns',
              toolPanel: 'agColumnsToolPanel',
              toolPanelParams: {
                suppressRowGroups: true,
                suppressValues: true,
                suppressPivots: true,
                suppressPivotMode: true,
                suppressColumnFilter: true,
                suppressColumnSelectAll: true,
                suppressColumnExpandAll: true,
              },
            },
          ],
          defaultToolPanel: 'columns',
        }
        this.filter=true;
      }else{
        this.gridOptions.sideBar= null
        this.filter=false;
      }
    },
    exportDataXLS(){
      this.gridApi.exportDataAsExcel();
    },
    exportDataCSV(){
      this.gridApi.exportDataAsCsv();
    },

    // Sidebar collaborator
    newCollaborator (id) { 
      this.$router.push({ path: '/new-collaborator' })
    },
  }
}
</script>
