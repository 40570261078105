<template>
  <div>
    <vs-sidebar
        position-right
        v-model="active"
        parent="body" default-index="55555551"
        class="items-no-padding">
        <!-- 
        hidden-background -->

        <div v-if="isLoadingCollaborator || isUpdatingCollaborator || isDeletingCollaborator" class="h-100">
            <div class="text-center flex-center h-100">
                <div class="spinner-border text-primary" role="status">
                <span class="sr-only">Chargement...</span>
                </div>
                <br />
                Chargement des détails du collaborateur...
            </div>
        </div> 

        <div v-else class="h-full">
            

            <div class="sidebar-header py-1 px-2">
                <div>
                    <h3>{{collaborator.firstName}} {{collaborator.lastName}}</h3>
                    <small v-show="collaborator.matricule">Matricule : {{collaborator.matricule}}</small>
                </div>
                <feather-icon icon="XIcon" size="24" @click.stop="active =!active" class="close-icon cursor-pointer"></feather-icon>
            </div>

            <vs-divider class="m-0" />

            <div class="sidebar-action py-05 px-2">
              <div class="d-flex align-items-center">
                  <b-button
                    v-if="archived"
                    variant="warning"
                    class="btn-icon"
                    @click="restoreCollaboratorLocal(collaborator.id, collaborator.firstName+' '+collaborator.lastName)"
                  >
                      <feather-icon icon="ArrowDownIcon" /> Restaurer
                  </b-button>
                  <b-button
                    v-else
                    class="btn-icon"
                    variant="primary"
                    @click="editCollaborator(collaborator.id)"
                    :disabled="archived"
                  >
                      <feather-icon icon="EditIcon" /> Modifier
                  </b-button>
              </div>
              <b-button-group>
                  <b-button
                  v-if="archived"
                    variant="outline-danger"
                    class="btn-icon"
                    @click="deleteCollaboratorLocal(collaborator.id, collaborator.firstName+' '+collaborator.lastName)"
                  >
                      <feather-icon icon="TrashIcon" />  Supprimer définitivement
                  </b-button>
                  <b-button
                    v-else
                    variant="outline-danger"
                    class="btn-icon"
                    @click="archiveCollaboratorLocal(collaborator.id, collaborator.firstName+' '+collaborator.lastName)"
                  >
                      <feather-icon icon="ArchiveIcon" /> 
                  </b-button>
              </b-button-group>
            </div>

            <vs-divider class="m-0" />

            <div class="sidebar-content">
                <div v-show="collaborator.email || collaborator.phoneNumber">
                    <h4 class="mb-1 text-primary"><feather-icon icon="PhoneCallIcon" /> Coordonnées</h4>
                    <ul class="listTab">
                        <li>
                            <span>Adresse e-mail</span>
                            <span>{{collaborator.email}}</span>
                        </li>
                        <li>
                            <span>Téléphone</span>
                            <span>{{collaborator.phoneNumber}}</span>
                        </li>
                    </ul>
                    <vs-divider /> 
                </div>
                <div v-show="collaborator.address || collaborator.zipCode || collaborator.city || collaborator.country">
                    <h4 class="mb-1 text-primary"><feather-icon icon="MapPinIcon" /> Adresse</h4>
                    <p class="paraphbox">
                        <span v-show="collaborator.address">{{collaborator.address}}<br></span>
                        <span v-show="collaborator.addressComplement">{{collaborator.addressComplement}}<br></span>
                        <span v-show="collaborator.zipCode || collaborator.city ">{{collaborator.zipCode}} {{collaborator.city}}<br></span>
                        <span v-show="collaborator.country ">{{collaborator.country}}</span>
                    </p>
                    <vs-divider /> 
                </div>
                <div v-show="collaborator.job || collaborator.contract || collaborator.matricule || collaborator.socialSecurityNumber">
                    <h4 class="mb-1 text-primary"><feather-icon icon="ClipboardIcon" /> Juridique</h4>
                    <ul class="listTab">
                        <li v-if="collaborator.job">
                            <span>Métier</span>
                            <span>{{collaborator.job.label}}</span>
                        </li>
                        <li v-if="collaborator.contract">
                            <span>Contrat</span>
                            <span>{{collaborator.contract.label}}</span>
                        </li>
                        <li v-show="collaborator.matricule">
                            <span>Matricule</span>
                            <span>{{collaborator.matricule}}</span>
                        </li>
                        <li v-show="collaborator.socialSecurityNumber">
                            <span>N° Sécurité Sociale</span>
                            <span>{{collaborator.socialSecurityNumber}}</span>
                        </li>
                    </ul>
                    <vs-divider /> 
                </div>
            </div>
        </div>
    </vs-sidebar>
  </div>
</template>


<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters, mapActions } from 'vuex'

export default {
  data () {
    return {
      archived:false,
      collaborator:{}
    }
  },
  computed: {
    ...mapGetters([
      "isUpdatingCollaborator",
      "collaboratorsList",
      "isLoadingCollaborator",
      "isDeletingCollaborator",
      "isOpenSidebarCollaborator",
    ]),
    active: {
      get ()    { return this.isOpenSidebarCollaborator },
      set (val) { this.$store.commit('SET_IS_OPEN_SIDEBAR_COLLABORATOR', val) }
    },
  },
  methods: {
    ...mapActions(["updateCollaborator", "deleteCollaborator", "isArchivingCollaborator", "archiveCollaborator", "fetchCollaborator", "fetchCollaboratorArchived","restoreCollaborator"]),

    getCollaboratorArchivedDetails(id, archived) {
      this.archived=archived
      if (id != '') {
        this.fetchCollaboratorArchived(id).then(res => {
          this.collaborator = res
          this.collaborator.jobId = res.job.id
          this.collaborator.contractId = res.contract.id
        })
      } else {
        this.collaborator={}
      }
    },
    getCollaboratorDetails(id) {
      this.archived=false
      if (id != '') {
        this.fetchCollaborator(id).then(res => {
          this.collaborator = res
          this.collaborator.jobId = res.job.id
          this.collaborator.contractId = res.contract.id
        })
      } else {
        this.collaborator={}
      }
    },

    editCollaborator(id){
        this.$store.commit('SET_IS_OPEN_SIDEBAR_COLLABORATOR', false)
        this.$router.push({ name: 'edit-collaborator', params: { id: id, title: "Edit : "+ this.collaborator.firstName + ' ' + this.collaborator.lastName, tips:"Editer le collaborateur : "+this.collaborator.firstName + ' ' + this.collaborator.lastName } })
    },
    
    archiveCollaboratorLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourrez à tout moment restaurer cette fiche en consultant la liste de vos archives.', {
          title:
            'Êtes-vous sûr de vouloir archiver "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Archiver',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.archiveCollaborator(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_COLLABORATOR', false)
          }
        })
    },
    restoreCollaboratorLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Vous pourez ré-utiliser cette fiche dans tous les modules de votre application.', {
          title:
            'Êtes-vous sûr de vouloir restaurer "' + label + '" ?',
          size: 'sm',
          okVariant: 'warning',
          okTitle: 'Restaurer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.restoreCollaborator(id);
            this.$store.commit('SET_IS_OPEN_SIDEBAR_COLLABORATOR', false)
          }
        })
    },
    deleteCollaboratorLocal (id, label) {
      this.$bvModal
        .msgBoxConfirm('Cette action est définitive et irréversible.', {
          title:
            'Êtes-vous sûr de vouloir supprimer "' + label + '" ?',
          size: 'sm',
          okVariant: 'danger',
          okTitle: 'Supprimer',
          cancelTitle: 'Annuler',
          cancelVariant: 'outline-primary',
          hideHeaderClose: true,
          centered: true
        })
        .then(value => {
          if (value) {
            this.deleteCollaborator(id)
            this.$store.commit('SET_IS_OPEN_SIDEBAR_COLLABORATOR', false)
          }
        })
    },
  },
  components: {
    VuePerfectScrollbar
  }
}
</script>

